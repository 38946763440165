<template>
  <!-- Coming soon page-->
  <div v-scroll="handleScroll">
    <!--begin header -->
    <header
      id="header"
      class="header"
    >
      <!--begin navbar-fixed-top -->
      <nav
        class="navbar navbar-expand-lg navbar-default navbar-fixed-top navbar-efide"
        style="background-color: rgba(38, 85, 154, 1)"
        :class="{opaque: opaque}"
      >
        <!--begin container -->
        <div class="container">
          <!--begin logo -->
          <a
            class="navbar-brand"
            href="/"
          >
            <b-img
              :src="imgUrl"
              class="navbar-efide-img"
              alt="efide_logo"
            />
          </a>
          <!--end logo -->
          <!--begin navbar-toggler -->
          <button
            class="navbar-toggler navbar-efide-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            @click="showOptions"
          >
            <span class="navbar-toggler-icon navbar-efide-toggler-icon">
              <i class="fas fa-bars" />
            </span>
          </button>
          <!--end navbar-toggler -->
          <!--begin navbar-collapse -->
          <div
            id="navbarCollapse"
            class="navbar-collapse collapse"
            :class="{show: collapse}"
            style=""
          >
            <!--begin navbar-nav -->
            <ul class="navbar-nav ml-auto">
              <li class="d-flex navbar-efide_links">
                <router-link
                  :to="{name: 'home', hash: '#about'}"
                >
                  ¿Qué te ofrecemos?
                </router-link>
              </li>
              <li class="d-flex navbar-efide_links">
                <router-link
                  :to="{name: 'home', hash: '#factoring'}"
                >
                  Factoring en 3 simples pasos
                </router-link>
              </li>
              <li class="d-flex navbar-efide_links">
                <router-link
                  :to="{name: 'home', hash: '#evaluation'}"
                >
                  ¿Por qué pre-evaluar?
                </router-link>
              </li>
              <li class="d-flex navbar-efide_links">
                <router-link
                  :to="{name: 'home', hash: '#advantage'}"
                >
                  Ventajas con Efide Factoring
                </router-link>
              </li>
              <!-- <li class="d-flex navbar-efide_links">
                <a href="/inicio/#advantage">
                  Ventajas con Efide Factoring
                </a>
              </li> -->
              <!-- <li class="discover-link navbar-efide_container-button">
                <a
                  href="#contact"
                  class="button-efide"
                >
                  ¡Pre-evalúa ahora!
                </a>
              </li> -->
            </ul>
            <!--end navbar-nav -->
          </div>
          <!--end navbar-collapse -->
        </div>
      <!--end container -->
      </nav>
    <!--end navbar-fixed-top -->
    </header>
    <!-- <section style="padding-top: 100px" /> -->
    <!--begin section-white -->
    <section
      id="factoring"
      class="section-white"
    >
      <!--begin container -->
      <div class="container">
        <!--begin row -->
        <div class="row">
          <!--begin col-md-12 -->
          <div class="col-md-12 text-center margin-bottom-10">
            <h2 class="section-title mb-3">
              Factoring
            </h2>
            <h4 class="section-title mb-3">
              ¡Cede tus facturas y obtén liquidez inmediata!
            </h4>
          </div>
          <!--end col-md-12 -->
        </div>
        <!--end row -->
      </div>
      <!--end container -->
      <!--begin services-wrapper -->
      <div class="services-wrapper">
        <!--begin container -->
        <div class="container">
          <!--begin row -->
          <div class="row justify-content-md-center px-3">
            <!--begin col-md-12 -->
            <div class="col-md-6 my-2 d-flex justify-content-md-center">
              <b-img
                :src="imgS1"
                class="mx-2 my-auto"
                style="height: 45px"
                alt="Sin tanto papeleo, ni procesos engorrosos"
              />
              <h5 class="section-title my-auto">
                Sin tanto papeleo, ni procesos engorrosos
              </h5>
            </div>
            <div class="col-md-6 my-2 d-flex justify-content-md-center">
              <b-img
                :src="imgS2"
                class="mx-2 my-auto"
                style="width: 45px"
                alt="Sin tanto papeleo, ni procesos engorrosos"
              />
              <h5 class="section-title my-auto">
                No necesitas experiencia bancaria
              </h5>
            </div>
            <div class="col-md-6 my-2 d-flex justify-content-md-center">
              <b-img
                :src="imgS3"
                class="mx-2 my-auto"
                style="width: 45px"
                alt="Sin tanto papeleo, ni procesos engorrosos"
              />
              <h5 class="section-title my-auto">
                No genera exposición en las centrales de riesgo
              </h5>
            </div>
            <div class="col-md-6 my-2 d-flex justify-content-md-center">
              <b-img
                :src="imgS4"
                class="mx-2 my-auto"
                style="width: 45px"
                alt="Sin tanto papeleo, ni procesos engorrosos"
              />
              <h5 class="section-title my-auto">
                Te libera de la gestión de cobranza
              </h5>
            </div>
          </div>
          <!--end row -->
        </div>
        <!--end container -->
      </div>
      <!--end services-wrapper -->
      <validation-observer ref="validationRequestForm">
        <b-form @submit.prevent="validationForm">
          <div class="my-3">
            <!--begin container -->
            <div class="container">
              <!--begin row -->
              <div class="row justify-content-md-center px-3">
                <div
                  class="col-md-11 text-center my-2 p-md-3 py-3"
                  style="background-color: #d8d8d8; border-radius: 8px"
                >
                  <div class="row justify-content-center px-2">
                    <p style="color: #092054">
                      ¿TIENES FACTURAS O LETRAS POR COBRAR?
                    </p>
                    <p style="color: #FC5B27; font-size: 14px">
                      Envíanos tus datos y a la brevedad te diremos si tu cliente precalifica para el factoring. Te enviamos una simulación a tu solicitud
                    </p>
                  </div>
                  <div class="row p-2 justify-content-center">
                    <b-col md="12 text-left">
                      <p
                        style="color: #111"
                      >
                        Tus datos
                      </p>
                    </b-col>
                    <!-- Names -->
                    <b-col md="10">
                      <b-form-group label-for="names">
                        <validation-provider
                          #default="{ errors }"
                          name="Nombres"
                          rules="max:100|required"
                          vid="names"
                        >
                          <b-form-input
                            id="names"
                            v-model="names"
                            placeholder="Nombres *"
                            :state="errors.length > 0 ? false:null"
                            name="names"
                            :disabled="processing"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- lastnames -->
                    <b-col md="10">
                      <b-form-group label-for="lastnames">
                        <validation-provider
                          #default="{ errors }"
                          name="Apellidos"
                          rules="max:30|required"
                          vid="lastnames"
                        >
                          <b-form-input
                            id="lastnames"
                            v-model="lastnames"
                            placeholder="Apellidos *"
                            :state="errors.length > 0 ? false:null"
                            name="lastnames"
                            :disabled="processing"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- ruc -->
                    <b-col md="10">
                      <b-form-group label-for="ruc">
                        <validation-provider
                          #default="{ errors }"
                          name="ruc"
                          rules="required|document:6"
                          vid="ruc"
                        >
                          <b-form-input
                            id="ruc"
                            v-model="ruc"
                            placeholder="RUC de tu empresa *"
                            :state="errors.length > 0 ? false:null"
                            name="ruc"
                            :formatter="formatterNumber"
                            :disabled="processing"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- phone -->
                    <b-col md="10">
                      <b-form-group label-for="phone">
                        <validation-provider
                          #default="{ errors }"
                          name="Teléfono"
                          rules="required|min:7|max:15"
                          vid="phone"
                        >
                          <b-form-input
                            id="phone"
                            v-model="phone"
                            placeholder="Teléfono *"
                            :state="errors.length > 0 ? false:null"
                            :formatter="formatterTel"
                            name="phone"
                            :disabled="processing"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- email -->
                    <b-col md="10">
                      <b-form-group label-for="email">
                        <validation-provider
                          #default="{ errors }"
                          name="Correo electrónico"
                          rules="required|email"
                          vid="email"
                        >
                          <b-form-input
                            id="email"
                            v-model="email"
                            placeholder="Correo electrónico *"
                            :state="errors.length > 0 ? false:null"
                            name="email"
                            :disabled="processing"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </div>
                  <div class="row p-2 justify-content-center">
                    <b-col md="12 text-left">
                      <p
                        style="color: #111"
                      >
                        Los datos de tu cliente
                      </p>
                      <small
                        v-if="!hasClient"
                        class="text-danger"
                      >
                        Complete la información de sus cliente adecuadamente
                      </small>
                    </b-col>
                    <b-col md="12">
                      <div class="table-responsive">
                        <table class="table table-borderless table-custom">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style="background-color: #d8d8d8;"
                              >
                                Ruc de tu cliente
                                <p style="color: #FC5B27; font-size: 14px">
                                  (*1)
                                </p>
                              </th>
                              <th
                                scope="col"
                                style="background-color: #d8d8d8;"
                              >
                                Número de factura
                                <p style="color: #FC5B27; font-size: 14px">
                                  (*2)
                                </p>
                              </th>
                              <th
                                scope="col"
                                style="background-color: #d8d8d8;"
                              >
                                Tipo de moneda
                                <p style="color: #FC5B27; font-size: 14px">
                                  (*2)
                                </p>
                              </th>
                              <th
                                scope="col"
                                style="background-color: #d8d8d8;"
                              >
                                Importe neto a cobrar
                                <p style="color: #FC5B27; font-size: 14px">
                                  (*2)
                                </p>
                              </th>
                              <th
                                scope="col"
                                style="background-color: #d8d8d8;"
                              >
                                Fecha real de cobro
                                <p style="color: #FC5B27; font-size: 14px">
                                  (*2)
                                </p>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- Receipts -->
                            <tr
                              v-for="( receipt, index ) in receipts"
                              :key="receipt+index"
                            >
                              <td
                                scope="row"
                                style="padding: 0.72rem 0.5rem;"
                              >
                                <b-form-group :label-for="`ruc${index}`">
                                  <validation-provider
                                    #default="{ errors }"
                                    name="Ruc"
                                    rules="document:6"
                                    :vid="`ruc${index}`"
                                  >
                                    <b-form-input
                                      :id="`ruc${index}`"
                                      v-model="receipt.ruc"
                                      placeholder="Ruc *"
                                      :state="errors.length > 0 ? false:null"
                                      :name="`ruc${index}`"
                                      :formatter="formatterNumber"
                                      :disabled="processing"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </td>
                              <td
                                scope="row"
                                style="padding: 0.72rem 0.5rem;"
                              >
                                <b-form-group :label-for="`receipt${index}`">
                                  <validation-provider
                                    #default="{ errors }"
                                    name="Factura"
                                    rules="max:20"
                                    :vid="`receipt${index}`"
                                  >
                                    <b-form-input
                                      :id="`receipt${index}`"
                                      v-model="receipt.receipt"
                                      placeholder="Factura *"
                                      :state="errors.length > 0 ? false:null"
                                      :name="`receipt${index}`"
                                      :disabled="processing"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </td>
                              <td
                                scope="row"
                                style="padding: 0.72rem 0.5rem;"
                              >
                                <b-form-group :label-for="`currency${index}`">
                                  <validation-provider
                                    #default="{ errors }"
                                    name="Tipo de moneda"
                                    rules="max:45"
                                    :vid="`currency${index}`"
                                  >
                                    <b-form-select
                                      :id="`currency${index}`"
                                      v-model="receipt.currency"
                                      placeholder="Tipo de moneda *"
                                      :options="optionsTypeCurrency"
                                      :state="errors.length > 0 ? false:null"
                                      :name="`currency${index}`"
                                      :disabled="processing"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </td>
                              <td
                                scope="row"
                                style="padding: 0.72rem 0.5rem;"
                              >
                                <b-form-group :label-for="`amount${index}`">
                                  <validation-provider
                                    #default="{ errors }"
                                    name="Importe neto"
                                    rules="money"
                                    :vid="`amount${index}`"
                                  >
                                    <b-form-input
                                      :id="`amount${index}`"
                                      v-model="receipt.amount"
                                      placeholder="Importe neto *"
                                      :state="errors.length > 0 ? false:null"
                                      :name="`amount${index}`"
                                      :formatter="formatterMoney"
                                      :disabled="processing"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </td>
                              <td
                                scope="row"
                                style="padding: 0.72rem 0.5rem;"
                              >
                                <b-form-group :label-for="`date${index}`">
                                  <validation-provider
                                    #default="{ errors }"
                                    name="Fecha real de cobro"
                                    rules="max:12"
                                    :vid="`date${index}`"
                                  >
                                    <!-- <b-form-datepicker
                                      :id="`date${index}`"
                                      v-model="receipt.date"
                                      placeholder="Fecha *"
                                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                      locale="es"
                                      :min="today"
                                      :state="errors.length > 0 ? false:null"
                                      :name="`date${index}`"
                                      :disabled="processing"
                                    /> -->
                                    <flat-pickr
                                      :id="`date${index}`"
                                      v-model="receipt.date"
                                      placeholder="dd/mm/aaaa"
                                      :name="`date${index}`"
                                      :state="errors.length > 0 ? false:null"
                                      :config="{ ...configDatePickr, minDate: today }"
                                      :disabled="processing"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </div>
                  <div class="row p-2 justify-content-center">
                    <b-col
                      md="12 text-left"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Declaración jurada"
                        vid="affirmedAffidavit"
                      >
                        <b-form-checkbox
                          id="affirmedAffidavit"
                          v-model="affirmedAffidavit"
                          name="affirmedAffidavit"
                          value="yes"
                          unchecked-value="no"
                          :state="errors.length > 0 ? false:null"
                          required
                        >
                          <b-link
                            target="_blank"
                            href="https://www.efide.com.pe/wp-content/uploads/ef/EF_PD_Politicas_de_Privacidad.pdf"
                          >
                            He leído y aceptado las políticas de privacidad
                          </b-link>
                        </b-form-checkbox>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col class="p-0 my-2">
                      <b-button
                        class="my-1"
                        variant="primary"
                        style="overflow-wrap: break-word; font-size: 14px;"
                        type="submit"
                        block
                        :disabled="!hasClient || processing"
                      >
                        <b-spinner
                          v-if="processing"
                          style="margin-bottom: 2px"
                          small
                        />
                        {{ processing ? 'CARGANDO...' : 'SOLICITUD DE PRE-EVALUACIÓN' }}
                      </b-button>
                    </b-col>
                  </div>
                  <div class="col text-left my-1">
                    <p style="color: #FC5B27; font-size: 12px">
                      *1 Registre el RUC de su cliente que requiera evaluación.
                    </p>
                    <p style="color: #FC5B27; font-size: 12px">
                      *2 Datos para la simulación: Registre los datos de la factura si requerie simulación.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </section>
    <!--end footer -->
    <footer class="footer-widgets widget-area d-flex justify-content-center">
      <div
        class="container row"
        style="justify-content: space-evenly"
      >
        <div class="col-lg-3 col-md-4">
          <aside
            id="custom_html-2"
            class="widget_text widget widget_custom_html"
          >
            <h3 class="widget-title text-center">
              Ubícanos
            </h3>
            <div class="textwidget custom-html-widget">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31209.650499770567!2d-76.97247900000002!3d-12.098024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbe0a7851c2fd51f!2sEFIDE+-+COOPERATIVA+DE+AHORRO+Y+CR%C3%89DITO!5e0!3m2!1ses!2sus!4v1521437748830"
                width="500"
                height="200"
                frameborder="0"
                style="border:0"
                allowfullscreen=""
              />
            </div>
          </aside>
        </div>
        <div class="col-lg-3 col-md-4">
          <aside
            id="sydney_contact_info-2"
            class="widget sydney_contact_info_widget"
          >
            <h3 class="widget-title text-center">
              Contáctenos
            </h3>
            <div class="contact">
              <span>
                <b-img
                  :src="imgAddress"
                  class="sydney-svg-icon"
                  alt="linkedin-logo"
                />
              </span>
              Av. El Derby Nº 254 Oficina 1106. Santiago de Surco. Lima Central Tower
            </div>
            <div class="contact">
              <span>
                <b-img
                  :src="imgPhone"
                  class="sydney-svg-icon"
                  alt="linkedin-logo"
                />
              </span> 208-5900 | 998 330 342 | 998 388 540
            </div>
            <div class="contact">
              <span>
                <b-img
                  :src="imgMail"
                  class="sydney-svg-icon"
                  alt="linkedin-logo"
                />
              </span>
              <a href="mailto:info@efide.com.pe">info@efide.com.pe</a>
            </div>
          </aside>
        </div>
        <div class="col-lg-3 col-md-4">
          <aside
            id="nav_menu-3"
            class="widget widget_nav_menu"
          >
            <h3 class="widget-title text-center">
              Síguenos
            </h3>
            <div class="menu-social-container">
              <ul
                id="menu-social"
                class="menu"
              >
                <li
                  id="menu-item-2154"
                  class="contact"
                >
                  <b-img
                    :src="imgLinkedin"
                    class="sydney-svg-icon"
                    alt="linkedin-logo"
                  />
                  <a
                    href="https://pe.linkedin.com/company/efide-servicios-financieros"
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                </li>
                <li
                  id="menu-item-1436"
                  class="contact"
                >
                  <b-img
                    :src="imgFacebook"
                    class="sydney-svg-icon"
                    alt="linkedin-logo"
                  />
                  <a
                    href="https://www.facebook.com/EfideCooperativa/"
                    target="_blank"
                  >
                    Facebook
                  </a>
                </li>
                <li
                  id="menu-item-2060"
                  class="contact"
                >
                  <b-img
                    :src="imgInstagram"
                    class="sydney-svg-icon"
                    alt="linkedin-logo"
                  />
                  <a
                    href="https://www.instagram.com/efidefactoring/"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </footer>
    <a
      id="myBtn"
      :style="{ display: displayValue}"
      class="efide-whatsapp-button"
      href="https://api.whatsapp.com/send?phone=+51998330342"
      target="_blank"
    >
      <b-img
        :src="imgWhatsapp"
        style="height: 65px"
        alt="whatsapp-icon"
      />
    </a>
  </div>
</template>

<script>
/* eslint-disable */
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
/* eslint-enable */

/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BImg, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BButton, BFormSelect, BSpinner, BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

require('@/assets/css/style.css')
require('@/assets/css/animate.css')
require('@/assets/css/pe-icon-7-stroke.css')
require('@/assets/css/style-magnific-popup.css')
require('@/assets/css/all.min.css')

export default {
  directives: {
    scroll: {
      inserted(el, binding) {
        function f(evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      },
    },
  },
  components: {
    BCol,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BButton,
    BImg,
    BFormSelect,
    BSpinner,
    flatPickr,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return {
      processing: false,
      today,
      opaque: false,
      required,
      collapse: false,
      displayValue: 'none',
      names: '',
      lastnames: '',
      ruc: '',
      phone: '',
      email: '',
      receipts: [
        {
          ruc: '',
          receipt: '',
          currency: '',
          amount: '',
          date: '',
        },
        {
          ruc: '',
          receipt: '',
          currency: '',
          amount: '',
          date: '',
        },
        {
          ruc: '',
          receipt: '',
          currency: '',
          amount: '',
          date: '',
        },
        {
          ruc: '',
          receipt: '',
          currency: '',
          amount: '',
          date: '',
        },
      ],
      optionsTypeCurrency: [
        { value: '', text: 'Seleccione una moneda', disabled: true },
        { value: '00036', text: 'Soles' },
        { value: '00037', text: 'Dolares' },
      ],
      hasClient: false,
      affirmedAffidavit: 'no',
      imgLinkedin: require('@/assets/images/efide/linkedin.svg'),
      imgFacebook: require('@/assets/images/efide/facebook.svg'),
      imgInstagram: require('@/assets/images/efide/instagram.svg'),
      imgAddress: require('@/assets/images/efide/address.svg'),
      imgMail: require('@/assets/images/efide/mail.svg'),
      imgPhone: require('@/assets/images/efide/phone.svg'),
      imgWhatsapp: require('@/assets/images/efide/whatsapp.svg'),
      imgS1: require('@/assets/images/efide/Sin tanto papeleo ni procesos engorrosos.svg'),
      imgS2: require('@/assets/images/efide/no necesitas experiencia bancaria.svg'),
      imgS3: require('@/assets/images/efide/No genera exposición en la centra de riesgo.svg'),
      imgS4: require('@/assets/images/efide/Te libera de la gestión de cobranza.svg'),

      // Configs DatePickr
      configDatePickr: {
        allowInput: true,
        altInput: true,
        locale: Spanish,
        altFormat: 'd/m/Y',
      },
    }
  },
  computed: {
    imgUrl() {
      let imgVal

      if (!this.opaque) {
        imgVal = require('@/assets/images/efide/efide_white.svg')
      } else {
        imgVal = require('@/assets/images/efide/efide_color.svg')
      }
      return imgVal
    },
    backgroundEfide() {
      return {
        background: `url(${this.imgBackground}) top center no-repeat`,
        'background-size': '100% 100%',
      }
    },
  },
  watch: {
    receipts: {
      handler() {
        for (let i = 0; i < this.receipts.length; i += 1) {
          let flag1 = false
          let flag2 = false
          if (this.receipts[i].ruc !== '') {
            if (this.receipts[i].receipt === '' && this.receipts[i].currency === '' && this.receipts[i].amount === '' && this.receipts[i].date === '') {
              flag1 = true
            }
            if (this.receipts[i].receipt !== '' && this.receipts[i].currency !== '' && this.receipts[i].amount !== '' && this.receipts[i].date !== '') {
              flag2 = true
            }
            if (flag2 || flag1) {
              this.hasClient = true
            } else if (!flag1) {
              this.hasClient = false
              break
            }
          }
        }
      },

      deep: true,

    },
  },
  mounted() {
    const gtm = document.createElement('script')
    gtm.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PB3PRM7');"
    document.head.insertBefore(gtm, document.head.firstElementChild)
    const gtmNoScript = document.createElement('noscript')
    gtmNoScript.innerHTML = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-PB3PRM7' height='0' width='0' style='display:none;visibility:hidden'></iframe>"
    document.body.insertBefore(gtmNoScript, document.body.firstElementChild)
  },
  methods: {
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    formatterTel(value) {
      return value.replace(/[^0-9()#\-+]/g, '')
    },
    formatterMoney(value) {
      return value.replace(/[^0-9.]/g, '')
    },
    handleScroll() {
      if (window.scrollY > 250) {
        this.opaque = true
        this.displayValue = 'block'
      } else {
        this.opaque = false
        this.displayValue = 'none'
      }
    },
    showOptions() {
      this.collapse = !this.collapse
    },
    validationForm() {
      this.$refs.validationRequestForm.validate().then(success => {
        if (success) {
          this.processing = true
          // API EFIDE
          const PlanillaCuotaIN = []
          const now = new Date()
          this.receipts.forEach(r => {
            const rDate = new Date(r.date)
            const diffDays = Math.ceil(Math.abs(rDate - now) / (1000 * 60 * 60 * 24))
            if (r.ruc !== '') {
              PlanillaCuotaIN.push({
                RazonSocial: '',
                RUCPag: r.ruc,
                IdDocumento: '00010',
                NroDoc: r.receipt,
                IdMoneda_tt: r.currency,
                FechaPago: r.date,
                Plazo: diffDays,
                ImporteFactura: r.amount,
              })
            }
          })
          useJwt.preEvaluationEfide({
            OPCION: 2,
            IdProspecto: '',
            RazonSocial: this.names.concat(' ', this.lastnames),
            RUC: this.ruc,
            Telefono: this.phone,
            Correo: this.email,
            UserEdit: 'ULanding',
            PlanillaCuotaIN,
          })
            .then(() => {
              this.processing = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Éxito',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Se ha enviado tu solicitud con éxito. En breve recibirás un correo con tu resultado.',
                },
              }, {
                timeout: 10000,
              })
            })
            .catch(error => {
              console.log('errores', error)
              this.processing = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: 'Error al enviar a Efide',
                },
              },
              {
                timeout: 8000,
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
